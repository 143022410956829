import http from 'axios'

const token = '1493eb8d47205816778f0fa1e390fe06'

export const vimeoApi = http.create({
  baseURL: 'https://api.vimeo.com/me/videos/',
  headers: { Authorization: `bearer ${token}` },
})

export const getVideoId = async url => {
  const encodedUrl = encodeURI(url)
  try {
    const res = await http.get(`https://vimeo.com/api/oembed.json?url=${encodedUrl}`)
    return res.data.video_id
  } catch (err) {
    return false
  }
}
