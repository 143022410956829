<template>
  <div>
    <v-text-field
      :value="value"
      :label="!isShortTerm ? $t('project.editProject.videoLabel') : $t('project.editProject.videoLabelMandatory')"
      :error-messages="errorMessages"
      type="url"
      outlined
      dense
      hide-details="auto"
      @input="event => event.includes('youtube') ? $emit('input', event.split('&')[0]) : $emit('input', event)"
      @blur="$emit('blur')"
    >
      <template #append-outer>
        <v-tooltip v-model="showUploadTooltip" bottom>
          <!-- v-model is need to fix a glitch where the tooltip keeps showing after closing the choose file pop-up -->
          <template #activator="{ on }">
            <v-icon color="primary" @click="$refs.realFileInput.click()" @mouseover="showUploadTooltip = true" @mouseout="showUploadTooltip = false">
              publish
            </v-icon>
          </template>
          {{ $t('formFields.uploadVideo') }}
        </v-tooltip>
        <v-divider v-if="value" vertical class="mx-2" />
        <v-tooltip v-if="value" bottom>
          <template #activator="{ on, attrs }">
            <a :href="value" target="_blank" v-bind="attrs" v-on="on">
              <v-icon color="primary">
                play_arrow
              </v-icon>
            </a>
          </template>
          {{ $t('formFields.playVideo') }}
        </v-tooltip>
      </template>
      <template #append>
        <div v-if="isUploading" class="primary--text mx-2 mt-1" v-text="`${uploadProgress} %`" />
        <div v-if="showSuccessMessage" class="success--text mx-2 mt-1" v-text="`Upload successful!`" />
      </template>
    </v-text-field>
    <input
      ref="realFileInput" type="file"
      accept="video/mp4"
      style="display: none;"
      @change="uploadVideo($event.target.files[0])"
    >
  </div>
</template>
<script>
import * as tusClient from 'tus-js-client'
import { vimeoApi } from '@/services/vimeo'

export default {
  name: 'VideoField',
  props: {
    value: { type: String, default: null },
    isShortTerm: { type: Boolean, default: false },
    errorMessages: { type: String, default: '' },
  },
  data() {
    return {
      isUploading: false,
      showSuccessMessage: false,
      uploadProgress: '0',
      showUploadTooltip: false,
    }
  },
  methods: {
    prepareToUpload() {
      this.isUploading = true
      this.$nextTick(() => { this.$emit('input', null) }) // Because of weird bug of upload icon disappearing
    },
    async finishUpload(newVideoData) {
      this.isUploading = false
      this.showSuccessMessage = true
      setTimeout(() => { this.showSuccessMessage = false }, 5000)
      this.uploadProgress = '0'
      this.$nextTick(() => { this.$emit('input', newVideoData.link) })
    },
    async uploadVideo(file) {
      if (!file) return
      this.prepareToUpload()
      const options = {
        upload: { approach: 'tus', size: file.size },
        name: file.name,
        privacy: { view: 'unlisted', embed: 'public' },
      }
      const newVideoData = (await vimeoApi.post(null, options)).data
      const uploadLink = newVideoData.upload.upload_link
      const upload = new tusClient.Upload(file, {
        uploadUrl: uploadLink,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        onError(err) { this.$store.dispatch('alert/openAlertBox', ['alertError', 'Upload failed']); console.error(err) },
        onProgress: (bytesUploaded, bytesTotal) => { this.uploadProgress = ((bytesUploaded / bytesTotal) * 100).toFixed() },
        onSuccess: () => this.finishUpload(newVideoData),
      })
      upload.start()
    },
  },
}
</script>
